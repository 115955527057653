export interface OperationConfig {
    label: string;
    fields: { [key: string]: string };
}
export const OPERATION_CONFIGS: { [key: string]: OperationConfig } = {
    CustomPayload: {
        label: 'Custom Payload',
        fields: {
            payload: 'Payload',
        },
    },
    DELETE_WARRANTY: {
        label: 'Delete Warranty',
        fields: { warrantyId: 'Warranty ID' },
    },
    REDRIVE_WARRANTY_TO_MV: {
        label: 'Redrive Warranty to Materialized View',
        fields: { warrantyId: 'Warranty ID' },
    },
    ROLLBACK_TRANSACTION: {
        label: 'Rollback Transaction',
        fields: { transactionId: 'Transaction ID' },
    },
    UNBIND: {
        label: 'Unbind',
        fields: {
            unbindType: 'Unbind Type',
            warrantyId: 'Warranty ID',
            dsn: 'Device Serial Number',
            orderId: 'Order ID',
        },
    },
    BIND_EXTENDED_WARRANTY: {
        label: 'Bind Extended Warranty',
        fields: {
            warrantyId: 'Warranty ID',
            dsn: 'Device Serial Number',
            deviceType: 'Device Type',
            orderId: 'Order ID',
            orderItemId: 'Order Item ID',
            orderLineItemId: 'Order Line Item ID',
            orderUnitId: 'Order Unit ID',
            marketplaceId: 'Marketplace ID',
        },
    },
    UPDATE_WARRANTY_CONFIGURATION: {
        label: 'Update Warranty Configuration',
        fields: {
            warrantyId: 'Warranty ID',
            fetchAsin: 'Fetch ASIN',
        },
    },
    INVALIDATE_BINDING: {
        label: 'Invalidate Binding',
        fields: {
            warrantyId: 'Warranty ID',
            bindingId: 'Binding ID',
        },
    },
    INVALIDATE_WARRANTY: {
        label: 'Invalidate Warranty',
        fields: {
            warrantyId: 'Warranty ID',
        },
    },
    CANCEL_CLAIM: {
        label: 'Cancel Claim',
        fields: {
            claimId: 'Claim ID',
        },
    },
    SET_WARRANTY_DATES: {
        label: 'Set Warranty Dates',
        fields: {
            warrantyId: 'Warranty ID',
            startDate: 'Start Date',
            expirationDate: 'Expiration Date',
        },
    },
    SET_WARRANTY_DATES_ON_DEVICE_FRO: {
        label: 'Set Warranty Dates on Device FRO',
        fields: {
            dsn: 'Device Serial Number',
            deviceType: 'Device Type',
        },
    },
    SET_WARRANTY_OWNER: {
        label: 'Set Warranty Owner',
        fields: {
            warrantyId: 'Warranty ID',
            obfuscatedCustomerId: 'Obfuscated Customer ID',
        },
    },
    GET_DEVICE_TYPE: {
        label: 'Get Device Type',
        fields: {
            dsn: 'Device Serial Number',
        },
    },
    RERUN_DATA_PUBLICATION: {
        label: 'Rerun Data Publication',
        fields: {
            warrantyId: 'Warranty ID',
            dataPublicationOperation: 'Data Publication Operation',
        },
    },
    GET_DEVICE_SHIPMENTS: {
        label: 'Get Device Shipments',
        fields: {
            dsn: 'Device Serial Number',
            deviceType: 'Device Type',
        },
    },
    CREATE_TEST_EW: {
        label: 'Create Test EW',
        fields: {
            marketplaceId: 'Marketplace ID',
            asin: 'ASIN',
            quantity: 'Quantity',
            orderDate: 'Order Date',
            obfuscatedCustomerId: 'Obfuscated Customer ID',
            useDefaults: 'Use Defaults',
            orderId: 'Order ID',
            orderItemId: 'Order Item ID',
            orderLineItemId: 'Order Line Item ID',
            sellerId: 'Seller ID',
        },
    },
    GET_EW_ASINS: {
        label: 'Get EW ASINs for Device',
        fields: {
            marketplaceId: 'Marketplace ID',
            asin: 'ASIN',
        },
    },
    GET_DEVICE_DETAILS: {
        label: 'Get Device Details',
        fields: {
            dsn: 'Device Serial Number',
            deviceType: 'Device Type',
        },
    },
    GET_FEATURE_FLAG: {
        label: 'Get Feature Flag Treatment',
        fields: {
            appConfigFeatureFlagName: 'AppConfig Feature Flag Name',
        },
    },
    GET_AWS_APP_CONFIG_CONFIGURATION: {
        label: 'Get AWS AppConfig Configuration',
        fields: {
            appConfigApplicationName: 'AppConfig Application Name',
            appConfigEnvironmentName: 'AppConfig Environment Name',
            appConfigConfigProfileName: 'AppConfig Config Profile Name',
        },
    },
    HANDLE_WARRANTY_RETENTION_LIFECYCLE: {
        label: 'Handle Warranty Retention Lifecycle',
        fields: {
            warrantyId: 'Warranty ID',
        },
    },
};